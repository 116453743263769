import myConfig from './myConfig';
import axios from 'axios';

const token = localStorage.getItem('token');
const annee = localStorage.getItem('annee');
export default {
    getAll(callback){
        axios.get(myConfig.api_url+`/api/v1/allCaisses?api_token=${token}&idAnnee=`+localStorage.getItem("annee")+`&centre=`+localStorage.getItem("centre"))
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    getAllByAllCenters(callback){
        axios.get(myConfig.api_url+`/api/v1/getAllByAllCenters?api_token=${token}&idAnnee=`+localStorage.getItem("annee"))
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    add(args,callback){

        //recette:?, libelle:?, montant:?, utilisateur_idutilisateur:?, centre:?
        axios.post(myConfig.api_url+`/api/v1/addCaisse?api_token=${token}`,{annee_idannee:annee,...args})
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    
    update(args,callback){
        axios.post(myConfig.api_url+`/api/v1/updateCaisseDate?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    delete(args,callback){
        //id:? ,
        axios.post(myConfig.api_url+`/api/v1/deleteCaisse?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

}