import myConfig from './myConfig';
import axios from 'axios';

const token = localStorage.getItem('token');
const annee = localStorage.getItem('annee');
export default { 
    

    getInscriptionTarifs(args,callback){

    axios.post(myConfig.api_url+`/api/v1/getInscriptionTarifs?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    getInscriptionFormule(args,callback){

        axios.post(myConfig.api_url+`/api/v1/getInscriptionFormule?api_token=${token}`,args)
            .then((response) => {
                if (response.status == 200) {
                    callback(response.data);
                }
                else{
                    callback(null);
                }
            })
            .catch(() => {
                callback(null);
            });
    
        },

    getAll(callback){

    axios.get(myConfig.api_url+`/api/v1/allInscriptions?api_token=${token}&centre=${localStorage.getItem('centre')}&annee=${localStorage.getItem('annee')}`)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    addInscription(args,callback){

    axios.post(myConfig.api_url+`/api/v1/addInscription?api_token=${token}`,{annee_idannee:annee,...args})
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    getAllPeriodesByInscription(args,callback){

        axios.post(myConfig.api_url+`/api/v1/getAllPeriodesByInscription?api_token=${token}`,args)
            .then((response) => {
                if (response.status == 200) {
                    callback(response.data);
                }
                else{
                    callback(null);
                }
            })
            .catch(() => {
                callback(null);
            });
    
        },

    addInscriptionPeriode(args,callback){

    axios.post(myConfig.api_url+`/api/v1/addInscriptionPeriode?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    updateInscriptionGroupe(args,callback){

        axios.post(myConfig.api_url+`/api/v1/updateInscriptionGroupe?api_token=${token}`,args)
            .then((response) => {
                if (response.status == 200) {
                    callback(response.data);
                }
                else{
                    callback(null);
                }
            })
            .catch(() => {
                callback(null);
            });
    
        },

    deleteInscription(args,callback){

    axios.post(myConfig.api_url+`/api/v1/deleteInscription?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

}